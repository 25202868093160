<template>
  <div class="version">
    <table>
      <tr>
        <th>Property</th>
        <th>Value</th>
      </tr>
      <tr>
        <td>App Version</td>
        <td>{{ buildInfo.appVersion }}</td>
      </tr>
      <tr>
        <td>Build Mode</td>
        <td>{{ buildInfo.nodeEnv }}</td>
      </tr>
      <tr>
        <td>Build Time</td>
        <td>{{ formattedDateFromTime(buildInfo.buildTime) }}</td>
      </tr>
      <tr>
        <td>Git Commit</td>
        <td>{{ buildInfo.gitCommit }}</td>
      </tr>
      <tr>
        <td>CI Build Time</td>
        <td>{{ formattedDateFromTime(buildInfo.ciBuildTime) }}</td>
      </tr>
      <tr>
        <td>CI Build Id</td>
        <td>{{ buildInfo.ciBuildId }}</td>
      </tr>
      <tr>
        <td>Modern Build</td>
        <td>{{ buildInfo.isModernBuild }}</td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import BuildInfo from '@/util/BuildInfo'

@Component
export default class Version extends Vue {
  private buildInfo = new BuildInfo()

  formattedDateFromTime(time: number): string {
    if (!time) {
      return ''
    }
    return new Date(time).toLocaleString()
  }
}
</script>

<style scoped>
table,
th,
tr,
td {
  border: 1px solid black;
  margin: 0px auto;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
}
</style>
